import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import axios from 'axios'

import Plyr from './Video/plyr'

import blank from '../assets/images/FFFFFF-0.png'

class WorkItem extends Component {
  state = {
    video_src: '',
    audio_src: '',
    active: false,
    loading: false,
    muted: true,
  }

  _convertHTMLEntity(text){
    const span = typeof document !== 'undefined' && document.createElement('span');

    return text.replace(/&[#A-Za-z0-9]+;/gi, (entity, position, text) => {
      span.innerHTML = entity;
      return span.innerText;
    });
  }

  _play = () => {
    this.refs.player.play()
  }

  _rewind = () => {
    this.refs.player.rewind()
  }

  _pause = () => {
    this.refs.player.pause()
  }

  _stop = () => {
    this.refs.player.stop()
  }

  render() {

    let { active, muted } = this.state

    let props = {
      style: this.props.style,
      className: `r3__work-item ${active && 'r3__work-item--active'}`,
    }

    let linkClick = (event) => {
      if (window.outerWidth < 768) return true
      event.preventDefault()
    }

    let { video_placeholder } = this.props.node.acf

    let thumbProps = {
      onClick: (event) => {
        //linkClick(event)
        this.setState({ active: !active, muted: !!active }, () => {
          this._play()
        })
      },
      onMouseOver: (event) => {
        this._play()
      },
      onMouseLeave: (event) => {
        if (!this.state.active) {
          this._stop()
          //this._rewind()
        }
      },
    }

    return (
      <div {...props}>
        <div className="r3__work__wrapper">
          <div className="r3__thumb" {...thumbProps}>
            
            <img src={video_placeholder?.source_url} alt={this.props.node.title} />

            <div className="r3__thumb-content">
              <h4 dangerouslySetInnerHTML={{__html: this.props.node.title }} />
              <p dangerouslySetInnerHTML={{__html: this.props.node.acf.introduction}} />
            </div>

          </div>

          <Link to={`/work/${this.props.node.slug}`}></Link>
        </div>
      </div>
    )
  }
}

WorkItem.propTypes = {
  node: PropTypes.object,
  //video_placeholder: PropTypes.string,
  //video_url: PropTypes.string,
  autoplay: PropTypes.bool,
  controls: PropTypes.bool,
};

export default WorkItem
