import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Link, { withPrefix } from 'gatsby-link'
import Helmet from "react-helmet"
import Fade from 'react-reveal/Fade'

import TemplateWrapper from '../components/layout'
import WorkItem from '../components/work-item';
import Player from '../components/Video/player';

class WorkPage extends Component {
  state = {
    active: {
      local: true,
      autoplay: true,
      loop: true,
      src: withPrefix('/videos/showreel.mp4'),
    },
    work: [],
    categories: [],
    category: typeof window !== 'undefined' && window.location.pathname !== '/work' && window.location.pathname !== '/work/' ? this.props.data.wordpressWpWorkCategory : {},
    ih: this._calcContHeight(),
    limit: 8,
  }

  constructor(props) {
    super(props)

    // sort work by menu_order
    this.props.data.allWordpressWpWork.edges.sort((a, b) => {
      return a.node.menu_order - b.node.menu_order
    })

  }

  componentDidMount() {
    window.addEventListener('resize', this._resize.bind(this))
    window.addEventListener('scroll', this._scroll.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._resize.bind(this))
    window.removeEventListener('scroll', this._scroll.bind(this))
  }

  _scroll() {
    let position = document.querySelector('.r3__section--work')?.getBoundingClientRect()
    if (!position) return

    if (position.bottom - window.innerHeight <= 600) {
      if (!this.timeout) {
      //if (!this.timeout && this.state.length < this.state.list) {
        this.timeout = setTimeout(() => {
          this.setState({ limit: this.state.limit + 4 })
          this.timeout = null
        }, 10)
      }
    } else {
      clearTimeout(this.timeout)
    }
  }

  _resize() {
    this.setState({ ih: this._calcContHeight() })
  }

  _calcContHeight() {
    let width = typeof window !== 'undefined' ? window.innerWidth - 40 : 0
    if (width > 1128) width = 1128
    return width * 0.49 * 0.6
  }

  _switchCategory(event, id) {
    event.preventDefault();

    let category = this.props.data.allWordpressWpWorkCategory.edges.find((el) => {
      return el.node.wordpress_id === id;
    }) || {};

    let url = `/work/${category.node ? category.node.slug : ''}`;

    if (window.location.pathname !== url)
      window.history.pushState(window.history.state, '', url);

    this.setState({ category: category.node || {} });
  }

  render() {

    let { category, active, ih, limit } = this.state;
    let categories = this.props.data.allWordpressWpWorkCategory.edges
    let work = this.props.data.allWordpressWpWork.edges

    let mobile = typeof window !== 'undefined' && window.innerWidth < 768;
    let workCount = work.length
    if (category && category.name) {
      workCount = work.reduce((a, b) => {
        if (b.node.work_category.includes(category.wordpress_id)) {
          return a + 1
        }
        return a
      }, 0)
    }
    let lower = limit > workCount ? workCount : limit
    let cont = mobile ? 'auto' : (lower + 1) / 2 * (ih + 20)
    let index = 0

    return (
      <TemplateWrapper {...this.props}>
        <Helmet>
          <title>{ 'Work Archive' }</title>
          <meta name="description" content={ 'Video Work Archive, features include docos, animations & corporate works.' } />
        </Helmet>
        <div>
          <div className="r3__head r3--dark r3--work">
            { active && <Player {...active} /> }
          </div>

          <ul className="r3__work__categories">
            { categories.map((el, i) => {
              if (el.count === 0) return false;
              return (
                <li key={i}>
                  <a
                    href={`/work/${el.node.slug}`}
                    className={category.slug === el.node.slug ? 'active' : ''}
                    onClick={(event) => this._switchCategory(event, el.node.wordpress_id)}
                    dangerouslySetInnerHTML={{__html: el.node.name}}>
                  </a>
                </li>
              )
            }) }
            <li>
              <Link to={'/case-study'}>Case Studies</Link>
            </li>
            <li>
              <a href={'/work'}
                className={!category.slug ? 'active' : ''}
                onClick={(event) => this._switchCategory(event)}>
                All
              </a>
            </li>
          </ul>

          { category.description &&
            <div className="r3__work__introduction"><p>{ category.description }</p></div>
          }


          <div className="r3__section r3__section--work">

            <Fade bottom distance='40px' cascade>

              <div className="r3__content r3__work" style={{height: cont}}>
                { work.map((el, i) => {
                  if (category.name && !el.node.work_category.includes(category.wordpress_id)) return null;
                  let style = {
                    left: mobile ? 'auto' : (index % 2 ? '50%' : 0),
                    top: mobile ? 'auto' : (index - (index % 2)) / 2 * (ih + 10),
                    position: mobile ? 'relative' : 'absolute',
                  }
                  index++
                  return (<WorkItem {...el} key={i} autoplay={false} style={style} loop={true} />)
                }).filter(el => el).slice(0, limit) }
              </div>

            </Fade>

          </div>

        </div>
      </TemplateWrapper>
    )
  }
}

export default WorkPage

export const pageQuery = graphql`
  query currentWorkCategoryQuery($id: String = "") {
    wordpressWpWorkCategory(id: { eq: $id }) {
      wordpress_id,
      name,
      slug,
      description,
    }
    allWordpressWpWorkCategory {
      edges {
        node {
          name
          slug
          wordpress_id
          description
        }
      }
    }
    allWordpressWpWork {
      edges {
        node {
          menu_order
          slug
          title
          work_category
          acf {
            video_url
            video_placeholder {
              source_url
            }
            introduction
            gallery {
              source_url
            }
            content
          }
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
          }
        }
      }
    }
  }
`
